.webmap {
    position: absolute;
    top: 33%;
    left: 2%;
    width: 976px;
    height: 559px;
}

.yCord {
    position: absolute;
    right: 505px;
    bottom: 660px;
    padding: 2px;
    margin-top: 0.33333rem;
    border: 1px solid #293742;
    box-shadow: none;
    border-radius: 0;
}

.xCord {
    position: absolute;
    right: 340px;
    bottom: 660px;
    padding: 2px;
    margin-top: 0.33333rem;
    border: 1px solid #293742;
    box-shadow: none;
    border-radius: 0;
}

.xCordLabel{
    position: absolute;
    right: 432px;
    bottom: 690px;
}

.yCordLabel{
    position: absolute;
    right: 610px;
    bottom: 690px;
}

.unitLabel{
    position: absolute;
    left: 50px;
    bottom: 690px;
}

.unitInput{
    position: absolute;
    left: 50px;
    bottom: 660px;
    padding: 2px;
    margin-top: 0.33333rem;
    border: 1px solid #293742;
    box-shadow: none;
    border-radius: 0;
}

.resolutionLabel{
    position: absolute;
    left: 225px;
    bottom: 690px;
}

.resolutionInput{
    position: absolute;
    left: 225px;
    bottom: 660px;
    padding: 5px 30px;
    margin-top: 0.33333rem;
    border: 1px solid #293742;
    box-shadow: none;
    border-radius: 0;
}

.priceLabel{
    position: absolute;
    right: 280px;
    bottom: 690px;
}

.priceInput{
    position: absolute;
    right: 156px;
    bottom: 660px;
    padding: 2px;
    margin-top: 0.33333rem;
    border: 1px solid #293742;
    box-shadow: none;
    border-radius: 0;
}

.calculateBtn{
    position: relative;
    height: 35px;
    right: -175px;
    bottom: 296px;
    background-color: #FFFFFF;
	border-radius: 2px;
	border: 1px solid #48AFF0;
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
	color: #48AFF0;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	height: 32px;
	line-height: 1.5715;
	padding: 4px 15px;
	touch-action: manipulation;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	user-select: none;
}

.btnSaveClose{
    height: 35px;
    right: -175px;
    bottom: 375px;
}

.radioBox{
    position: absolute;
    position: absolute;
    left: 50px;
    bottom: 735px;
}