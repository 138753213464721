@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@200;400;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
html,
body,
button,
#root {
	height: 100%;
	font-family: 'Lato';
}

:focus {
	outline: none;
}

#root .ant-form-explain {
	font-size: 12px;
}

#root h1,
#root h2,
#root h3 {
	font-weight: 700;
}

#root .ant-form label {
	font-size: 12px;
}

#root .ant-col {
	flex-grow: 1;
}

#root .ant-btn {
	text-transform: uppercase;
	font-weight: 700;
}

#root input:-webkit-autofill,
#root input:-webkit-autofill:hover, 
#root input:-webkit-autofill:focus, 
#root input:-webkit-autofill:active  {
    box-shadow: 0 0 0 30px white inset !important;
}

/* Fix for issue with icon not rendering inside of flex element */

#root .ant-btn .anticon.anticon-plus > svg, #root .ant-btn .anticon.anticon-minus > svg {
	shape-rendering: auto;
}

.group { 
    position: relative; 
    margin-bottom: 45px; 
  }

.input-form {
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 418px;
    border: none;
    border-bottom: 1px solid #757575;
}
.input-form:focus { 
    outline: none; 
}
  
/* LABEL ======================================= */
.label-form {
    color: #999; 
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all; 
    -moz-transition: 0.2s ease all; 
    -webkit-transition: 0.2s ease all;
}
  
  /* active state */
.input-form:focus ~ .label-form, .input-form:valid ~ .label-form {
    top: -20px;
    font-size: 14px;
    color: #2196F3;
}
  
/* BOTTOM BARS ================================= */
.bar { 
    position: relative; 
    display: block; 
    width: 418px; 
}

.bar:before, .bar:after {
    content: '';
    height: 2px; 
    width: 0;
    bottom: 1px; 
    position: absolute;
    background: #2196F3; 
    transition: 0.2s ease all; 
    -moz-transition: 0.2s ease all; 
    -webkit-transition: 0.2s ease all;
}

.bar:before {
    left: 50%;
}

.bar:after {
    right: 50%; 
}
  
/* active state */
.input-form:focus ~ .bar:before, .input-form:focus ~ .bar:after {
    width: 50%;
}
  
/* HIGHLIGHTER ================================== */
.highlight {
    position: absolute;
    height: 60%; 
    width: 418px; 
    top: 25%; 
    left: 0;
    pointer-events: none;
    opacity: 0.5;
}
  
  /* active state */
.input-form:focus ~ .highlight {
    animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */

@keyframes inputHighlighter {
    from { background: #2196F3; }
    to 	{ width: 0; background: transparent; }
}
.dropdown {
    color: #2E2E2E !important;
}
.webmap {
    position: absolute;
    top: 33%;
    left: 2%;
    width: 976px;
    height: 559px;
}

.yCord {
    position: absolute;
    right: 505px;
    bottom: 660px;
    padding: 2px;
    margin-top: 0.33333rem;
    border: 1px solid #293742;
    box-shadow: none;
    border-radius: 0;
}

.xCord {
    position: absolute;
    right: 340px;
    bottom: 660px;
    padding: 2px;
    margin-top: 0.33333rem;
    border: 1px solid #293742;
    box-shadow: none;
    border-radius: 0;
}

.xCordLabel{
    position: absolute;
    right: 432px;
    bottom: 690px;
}

.yCordLabel{
    position: absolute;
    right: 610px;
    bottom: 690px;
}

.unitLabel{
    position: absolute;
    left: 50px;
    bottom: 690px;
}

.unitInput{
    position: absolute;
    left: 50px;
    bottom: 660px;
    padding: 2px;
    margin-top: 0.33333rem;
    border: 1px solid #293742;
    box-shadow: none;
    border-radius: 0;
}

.resolutionLabel{
    position: absolute;
    left: 225px;
    bottom: 690px;
}

.resolutionInput{
    position: absolute;
    left: 225px;
    bottom: 660px;
    padding: 5px 30px;
    margin-top: 0.33333rem;
    border: 1px solid #293742;
    box-shadow: none;
    border-radius: 0;
}

.priceLabel{
    position: absolute;
    right: 280px;
    bottom: 690px;
}

.priceInput{
    position: absolute;
    right: 156px;
    bottom: 660px;
    padding: 2px;
    margin-top: 0.33333rem;
    border: 1px solid #293742;
    box-shadow: none;
    border-radius: 0;
}

.calculateBtn{
    position: relative;
    height: 35px;
    right: -175px;
    bottom: 296px;
    background-color: #FFFFFF;
	border-radius: 2px;
	border: 1px solid #48AFF0;
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
	color: #48AFF0;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	height: 32px;
	line-height: 1.5715;
	padding: 4px 15px;
	touch-action: manipulation;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.btnSaveClose{
    height: 35px;
    right: -175px;
    bottom: 375px;
}

.radioBox{
    position: absolute;
    position: absolute;
    left: 50px;
    bottom: 735px;
}
