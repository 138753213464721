@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');


html,
body,
button,
#root {
	height: 100%;
	font-family: 'Lato';
}

:focus {
	outline: none;
}

#root .ant-form-explain {
	font-size: 12px;
}

#root h1,
#root h2,
#root h3 {
	font-weight: 700;
}

#root .ant-form label {
	font-size: 12px;
}

#root .ant-col {
	flex-grow: 1;
}

#root .ant-btn {
	text-transform: uppercase;
	font-weight: 700;
}

#root input:-webkit-autofill,
#root input:-webkit-autofill:hover, 
#root input:-webkit-autofill:focus, 
#root input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
}

/* Fix for issue with icon not rendering inside of flex element */

#root .ant-btn .anticon.anticon-plus > svg, #root .ant-btn .anticon.anticon-minus > svg {
	shape-rendering: auto;
}
