.group { 
    position: relative; 
    margin-bottom: 45px; 
  }

.input-form {
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 418px;
    border: none;
    border-bottom: 1px solid #757575;
}
.input-form:focus { 
    outline: none; 
}
  
/* LABEL ======================================= */
.label-form {
    color: #999; 
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all; 
    -moz-transition: 0.2s ease all; 
    -webkit-transition: 0.2s ease all;
}
  
  /* active state */
.input-form:focus ~ .label-form, .input-form:valid ~ .label-form {
    top: -20px;
    font-size: 14px;
    color: #2196F3;
}
  
/* BOTTOM BARS ================================= */
.bar { 
    position: relative; 
    display: block; 
    width: 418px; 
}

.bar:before, .bar:after {
    content: '';
    height: 2px; 
    width: 0;
    bottom: 1px; 
    position: absolute;
    background: #2196F3; 
    transition: 0.2s ease all; 
    -moz-transition: 0.2s ease all; 
    -webkit-transition: 0.2s ease all;
}

.bar:before {
    left: 50%;
}

.bar:after {
    right: 50%; 
}
  
/* active state */
.input-form:focus ~ .bar:before, .input-form:focus ~ .bar:after {
    width: 50%;
}
  
/* HIGHLIGHTER ================================== */
.highlight {
    position: absolute;
    height: 60%; 
    width: 418px; 
    top: 25%; 
    left: 0;
    pointer-events: none;
    opacity: 0.5;
}
  
  /* active state */
.input-form:focus ~ .highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
    from { background: #2196F3; }
    to 	{ width: 0; background: transparent; }
}

@-moz-keyframes inputHighlighter {
    from { background: #2196F3; }
    to 	{ width: 0; background: transparent; }
}

@keyframes inputHighlighter {
    from { background: #2196F3; }
    to 	{ width: 0; background: transparent; }
}